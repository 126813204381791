<template>
  <base-input :rules="internalRules" :errors="errors">
    <slot />
  </base-input>
</template>

<script>
import BaseInput from '../base/BaseInput';
import validatable from '../mixins/validatable';

export default {
  mixins: [validatable],
  props: {
    value: [String, Number, Boolean, Array],
  },
  components: {
    BaseInput,
  },
};
</script>
