<template>
  <base-input :rules="internalRules" :errors="errors">
    <label class="form-label" :for="id" v-if="label">{{ label }}</label>
    <input
      :type="type"
      :class="['form-control', { 'is-invalid': errors.length }]"
      :placeholder="placeholder"
      :value="value"
      @input="$emit('input', $event.target.value)"
      :id="id"
      @blur="validate"
    />
  </base-input>
</template>

<script>
import BaseInput from './BaseInput';
import validatable from '../mixins/validatable';
import { uniqueId } from 'lodash';

export default {
  mixins: [validatable],
  components: {
    BaseInput,
  },
  props: {
    label: String,
    placeholder: String,
    value: String,
    type: {
      type: String,
      default: 'text',
    },
  },
  data () {
    return {
      id: uniqueId('text-field-'),
    };
  },
};
</script>
