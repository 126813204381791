<template>
  <div :class="['spinner-border', `text-${color}`]" role="status">
    <span class="visually-hidden">Loading...</span>
  </div>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: 'primary',
    },
  },
};
</script>
