<template>
  <div class="position-relative" v-clickoutside="handleClickOutside">
    <input
      :value="value ? value.student_name : null"
      v-bind="$attrs"
      class="form-select"
      placeholder="Select student"
      autocomplete="off"
      @keypress="$event.preventDefault()"
      @click="openOptions"
    />
    <div
      class="rounded position-absolute bg-white shadow px-2 w-100 options-wrapper"
      v-show="isOpen"
    >
      <div class="pt-3 pb-2 px-2 position-sticky top-0 bg-white">
        <input
          v-model="search"
          placeholder="Type here to search students"
          class="form-control"
          ref="search"
        />
      </div>
      <div v-if="areStudentsLoading" class="py-3 my-1 d-flex justify-content-center">
        <base-spinner />
      </div>
      <div v-else-if="students.length" class="pb-2">
        <div
          v-for="student in students"
          :key="student.student_id"
          class="p-2 rounded student-option"
          @click="handleInput(student)"
        >{{ student.student_name }}</div>
      </div>
      <div class="py-4 px-3 text-muted" v-else>No students found.</div>
    </div>
  </div>
</template>

<script>
import BaseSpinner from './base/BaseSpinner';
import studentServices from '../services/studentServices';

const debounce = function (func, timeout = 300) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => { func.apply(this, args); }, timeout);
  };
};

export default {
  directives: {
    clickoutside: {
      inserted: (el, binding, vnode) => {
        el.clickOutsideEvent = function (event) {
          if (!(el === event.target || el.contains(event.target))) {
            vnode.context[binding.expression](event);
          }
        };
        document.body.addEventListener('click', el.clickOutsideEvent);
        document.body.addEventListener('touchstart', el.clickOutsideEvent);
      },
      unbind: function (el) {
        document.body.removeEventListener('click', el.clickOutsideEvent);
        document.body.removeEventListener('touchstart', el.clickOutsideEvent);
      },
      stopProp (event) {
        event.stopPropagation();
      },
    },
  },
  components: {
    BaseSpinner,
  },
  props: {
    value: Object,
    existingMembers: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      areStudentsLoading: false,
      isOpen: false,
      search: '',
      students: [],
    };
  },
  computed: {
    exisitingStudentIds() {
      return this.existingMembers
        .map(member => member.student ? member.student.id : null)
        .filter(id => id)
    }
  },
  created () {
    this.debounceSearchStudents = debounce(this.searchStudents);
  },
  methods: {
    handleClickOutside () {
      this.isOpen = false;
      this.search = '';
    },
    handleInput (input) {
      this.$emit('input', input);
      this.search = '';
      this.isOpen = false;
    },
    openOptions () {
      this.isOpen = true;
      this.$nextTick(() => (this.$refs.search.focus()));
    },
    async searchStudents () {
      try {
        this.areStudentsLoading = true;
        const response = await studentServices.getStudents({
          search: this.search,
          status: 'true',
          page: 0,
        });
        this.students = response.students.filter(member => !this.exisitingStudentIds.includes(member.id));
      } finally {
        this.areStudentsLoading = false;
      }
    },
  },
  watch: {
    search () {
      this.debounceSearchStudents();
    },
  },
};
</script>

<style lang="scss" scoped>
.student-option {
  cursor: pointer;
  &:hover {
    background-color: #efefef;
  }
}
.options-wrapper {
  max-height: 30rem;
  overflow: auto;
  z-index: 1000;
}
</style>
