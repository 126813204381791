import { render, staticRenderFns } from "./StudentSelector.vue?vue&type=template&id=765d7927&scoped=true"
import script from "./StudentSelector.vue?vue&type=script&lang=js"
export * from "./StudentSelector.vue?vue&type=script&lang=js"
import style0 from "./StudentSelector.vue?vue&type=style&index=0&id=765d7927&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "765d7927",
  null
  
)

export default component.exports