import axios from 'axios';

export default {
  getGroupMembers: async ({groupId}) => {
    const response = await axios.get(`/groups/${groupId}/students`);

    return response.data;
  },
  addStudentToGroup: async ({ studentId, groupId }) => {
    await axios.post(`/groups/${groupId}/students`, {
      student_id: studentId,
    })
  },
  removeStudentFromGroup: async ({ studentId, groupId }) =>  {
    await axios.delete(`/groups/${groupId}/students/${studentId}`, {
      student_id: studentId,
    })
  },
};
