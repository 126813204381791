<template>
  <template-type-with-answers :element="element" @input="$emit('input', $event)" />
</template>

<script>
import TemplateTypeWithAnswers from './TemplateTypeWithAnswers';

export default {
  components: {
    TemplateTypeWithAnswers,
  },
  model: {
    prop: 'element',
  },
  props: {
    element: {
      type: Object,
      required: true,
    },
  },
};
</script>
