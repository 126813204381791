<template>
    <div>
        <div class="d-flex align-items-center">
            <student-selector
                v-model="selectedStudent"
                id="student-selector"
                class="flex-grow-1"
                :existing-members="members"
            />
            <base-button
                :loading="isAddingStudent"
                id="add-student-button"
                @click="addStudent"
                :disabled="!selectedStudent"
                class="flex-shrink-0"
            >Add Student</base-button>
        </div>
        <div v-if="areMembersLoading" class="d-flex justify-content-center py-5">
            <base-spinner />
        </div>
        <div class="overflow-auto" v-else>
            <base-table :fields="memberFields" :items="members" class="mt-3">
                <template #name="{ item: member }">
                    <a :href="`/students/${member.student ? member.student.id : null}`" class="link-primary text-decoration-none">{{ member.student ? member.student.name : '' }}</a>
                </template>

                <template #identification_number="{ item: member }">
                    {{ member.student ? member.student.identification_number : '' }}
                </template>

                <template #actions="{ item: member }">
                    <base-button
                        type="danger"
                        size="sm"
                        :loading="member.student && member.student.id === memberLoading"
                        @click="removeStudent(member.student)"
                        :disabled="!!memberLoading"
                    >Remove</base-button>
                </template>
                <template #empty-state>
                    <div class="py-4 px-3 text-muted">No students in group.</div>
                </template>
            </base-table>
        </div>
    </div>
</template>

<script>
import StudentSelector from './StudentSelector.vue';
import BaseSpinner from './base/BaseSpinner';
import BaseButton from './base/BaseButton';
import BaseTable from './base/BaseTable';
import groupServices from '../services/groupServices';

export default {
    components: {
        StudentSelector,
        BaseSpinner,
        BaseButton,
        BaseTable,
    },
    props: {
        regionId: {
            type: String,
            required: true,
        },
        groupId: {
            type: String,
            required: true,
        }
    },
    data () {
        return {
            isAddingStudent: false,
            areMembersLoading: false,
            memberLoading: null,
            selectedStudent: null,
            memberFields: [
                { key: 'name', label: 'Student Name' },
                { key: 'identification_number', label: 'Student ID'},
                { key: 'actions', label: '' },
            ],
            members: []
        };
    },
    created() {
        this.getGroupMembers();
    },
    methods: {
        async getGroupMembers() {
            try {
                this.areMembersLoading = true;
                this.members = await groupServices.getGroupMembers({
                    groupId: this.groupId
                })
            } finally {
                this.areMembersLoading = false;
            }
        },
        async addStudent () {
            try {
                this.isAddingStudent = true;
                await groupServices.addStudentToGroup({
                    groupId: this.groupId,
                    studentId: this.selectedStudent.id,
                });
                this.selectedStudent = null;
                this.getGroupMembers();
            } finally {
                this.isAddingStudent = false;
            }
        },
        async removeStudent (student) {
            try {
                this.memberLoading = student.id;
                await groupServices.removeStudentFromGroup({
                    groupId: this.groupId,
                    studentId: student.id,
                });
                this.getGroupMembers();
            } finally {
                this.memberLoading = null;
            }
        },
    },
};
</script>

<style lang="scss">
#student-selector {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-right: 0;
}

#add-student-button {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-left: 0;
    z-index: 1;
}
</style>
