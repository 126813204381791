<template>
  <template-type-with-answers
    :free-text-type-options="freeTextTypeOptions"
    :element="element"
    @input="$emit('input', $event)"
  />
</template>

<script>
import TemplateTypeWithAnswers from './TemplateTypeWithAnswers';
import freeTextTypes from '../../../constants/freeTextTypes';

export default {
  components: {
    TemplateTypeWithAnswers,
  },
  model: {
    prop: 'element',
  },
  props: {
    element: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      freeTextTypeOptions: [
        { label: 'No free text field', value: freeTextTypes.NONE },
        { label: 'Allow one line of response', value: freeTextTypes.ONE_LINE },
        { label: 'Allow multi-line-response', value: freeTextTypes.MULTI_LINE },
      ],
    };
  },
};
</script>
