<template>
    <div>
        <div v-if="isEditing" class="d-flex align-items-center py-3 border-bottom">
            <div class="order-column" />
            <div class="name-column">
                <base-form ref="form" v-model="isFormValid">
                    <div class="d-flex align-items-center mb-3">
                        <base-select
                            placeholder="Select type"
                            :options="typeOptions"
                            labelKey="longLabel"
                            v-model.number="editElement.type"
                        />
                        <base-button type="default" @click="$emit('delete')">
                            <trash-icon class="text-muted icon" />
                        </base-button>
                    </div>
                    <component :is="selectedTemplateType" v-model="editElement" />
                    <div class="d-flex align-items-center justify-content-between mt-2">
                        <base-checkbox
                            value="isRequired"
                            v-model="editElement.isRequired"
                            v-if="canAnswerBeRequired"
                        >Require answer</base-checkbox>
                        <div v-else />
                        <div class="d-flex">
                            <base-button size="sm" type="light" class="mx-2" @click="cancel">Cancel</base-button>
                            <base-button size="sm" @click="save" :disabled="!isFormValid">Save</base-button>
                        </div>
                    </div>
                </base-form>
            </div>
        </div>
        <div class="d-flex align-items-center py-2 border-bottom" v-else>
            <div class="order-column">
                <menu-icon class="move-icon icon" />
            </div>
            <div class="name-column">
                <base-button type="link" @click="isEditing = true" button-class="p-0 text-start">{{ name }}</base-button>
            </div>
            <div class="type-column">{{ getElementTypeName(element.type) }}</div>
            <div class="required-column">
                <check-icon class="icon" v-if="element.isRequired && canAnswerBeRequired" />
            </div>
            <div class="action-column">
                <base-button type="default" @click="$emit('delete')">
                    <trash-icon class="icon text-muted" />
                </base-button>
            </div>
        </div>
    </div>
</template>

<script>

import TemplateTypeLongAnswer from './templateTypes/TemplateTypeLongAnswer';
import TemplateTypeShortAnswer from './templateTypes/TemplateTypeShortAnswer';
import TemplateTypeShortAnswerGroups from './templateTypes/TemplateTypeShortAnswerGroups';
import TemplateTypeChoice from './templateTypes/TemplateTypeChoice';
import TemplateTypeDropdown from './templateTypes/TemplateTypeDropdown';
import TemplateTypeParagraph from './templateTypes/TemplateTypeParagraph';
import TemplateTypeDate from './templateTypes/TemplateTypeDate';
import TemplateTypeSectionHeader from './templateTypes/TemplateTypeSectionHeader';
import TemplateTypeSectionBreak from './templateTypes/TemplateTypeSectionBreak';


import BaseCheckbox from '../base/BaseCheckbox';
import BaseButton from '../base/BaseButton';
import BaseSelect from '../base/BaseSelect';
import BaseForm from '../base/BaseForm';

import  TrashIcon from '../../icons/TrashIcon';
import  MenuIcon from '../../icons/MenuIcon';
import  CheckIcon from '../../icons/CheckIcon';

import { cloneDeep } from 'lodash';
import elementTypes from '../../constants/elementTypes.js';

export default {
    components: {
        TemplateTypeLongAnswer,
        TemplateTypeShortAnswer,
        TemplateTypeChoice,
        TemplateTypeShortAnswerGroups,
        TemplateTypeDropdown,
        TemplateTypeDate,
        TemplateTypeParagraph,
        TemplateTypeSectionHeader,
        TemplateTypeSectionBreak,
        BaseButton,
        BaseSelect,
        BaseCheckbox,
        BaseForm,
        TrashIcon,
        MenuIcon,
        CheckIcon,
    },
    model: {
        prop: 'element',
    },
    props: {
        element: {
            type: Object,
            required: true,
        },
    },
    data () {
        return {
            isEditing: this.element.isEditing,
            isFormValid: true,
            editElement: cloneDeep(this.element),
            typeOptions: [
                { value: elementTypes.LONG_ANSWER, longLabel: 'Long Answer (Multiple-line text)', shortLabel: 'Long Answer' },
                { value: elementTypes.SHORT_ANSWER, longLabel: 'Short Answer (Single-line text)', shortLabel: 'Short Answer' },
                { value: elementTypes.SINGLE_CHOICE, longLabel: 'Single Choice (Radio Buttons)', shortLabel: 'Single Choice' },
                { value: elementTypes.MULTIPLE_CHOICE, longLabel: 'Multiple Choice (Checkboxes)', shortLabel: 'Multiple Choice' },
                { value: elementTypes.SHORT_ANSWER_GROUPS, longLabel: 'Short Answer (Question Groups)', shortLabel: 'Short Answer' },
                { value: elementTypes.DROPDOWN, longLabel: 'Dropdown', shortLabel: 'Dropdown' },
                { value: elementTypes.DATE, longLabel: 'Date Field (Calendar)', shortLabel: 'Date Field' },
                { value: elementTypes.PARAGRAPH, longLabel: 'Paragraph Text (No Client Input)', shortLabel: '-- Paragraph Text --' },
                { value: elementTypes.SECTION_HEADER, longLabel: 'Section Header', shortLabel: '-- Section Header --' },
                { value: elementTypes.SECTION_BREAK, longLabel: 'Section Break', shortLabel: '-- Section Break --' },
            ],
        };
    },
    computed: {
        selectedTemplateType () {
            switch (this.editElement.type) {
                case elementTypes.LONG_ANSWER:
                    return 'template-type-long-answer';
                case elementTypes.SHORT_ANSWER:
                    return 'template-type-short-answer';
                case elementTypes.SINGLE_CHOICE:
                case elementTypes.MULTIPLE_CHOICE:
                    return 'template-type-choice';
                case elementTypes.SHORT_ANSWER_GROUPS:
                    return 'template-type-short-answer-groups';
                case elementTypes.DROPDOWN:
                    return 'template-type-dropdown';
                case elementTypes.DATE:
                    return 'template-type-date';
                case elementTypes.PARAGRAPH:
                    return 'template-type-paragraph';
                case elementTypes.SECTION_HEADER:
                    return 'template-type-section-header';
                case elementTypes.SECTION_BREAK:
                    return 'template-type-section-break';
            }
            return '';
        },
        name () {
            switch (this.editElement.type) {
                case elementTypes.LONG_ANSWER:
                case elementTypes.SHORT_ANSWER:
                case elementTypes.SINGLE_CHOICE:
                case elementTypes.MULTIPLE_CHOICE:
                case elementTypes.SHORT_ANSWER_GROUPS:
                case elementTypes.DROPDOWN:
                case elementTypes.DATE:
                case elementTypes.PARAGRAPH:
                case elementTypes.SECTION_HEADER:
                    return this.element.prompt;
                case elementTypes.SECTION_BREAK:
                    return 'Section Break';
            }
            return '';
        },
        canAnswerBeRequired () {
            return ![elementTypes.PARAGRAPH, elementTypes.SECTION_HEADER, elementTypes.SECTION_BREAK].includes(this.editElement.type);
        },
    },
    methods: {
        save () {
            if (!this.$refs.form.validate()) return;
            this.$emit('input', {
                ...this.editElement,
                isNew: false,
                isEditing: false,
            });
            this.isEditing = false;
        },
        getElementTypeName (type) {
            const option = this.typeOptions.find(to => to.value === type);
            return option ? option.shortLabel : '';
        },
        cancel () {
            if (this.element.isNew) {
                this.$emit('delete');
            } else {
                this.isEditing = false;
            }
        },
    },
    watch: {
        isEditing () {
            if (this.isEditing) {
                this.editElement = cloneDeep(this.element);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.icon {
    height: 24px;
    width: 24px;
}
.move-icon {
    cursor: grab;
}
</style>
