<template>
  <template-type-with-answers
    :free-text-type-options="freeTextTypeOptions"
    :element="element"
    @input="$emit('input', $event)"
  />
</template>

<script>
import TemplateTypeWithAnswers from './TemplateTypeWithAnswers';
import freeTextTypes from '../../../constants/freeTextTypes';

export default {
  components: {
    TemplateTypeWithAnswers,
  },
  model: {
    prop: 'element',
  },
  props: {
    element: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      freeTextTypeOptions: [
        { label: 'Short Text Entry', value: freeTextTypes.SHORT_TEXT },
        { label: 'Dropdown (autocomplete)', value: freeTextTypes.DROPDOWN },
      ],
    };
  },
};
</script>
