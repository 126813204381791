<template>
  <div class="form-check">
    <input
      :id="id"
      class="form-check-input"
      type="checkbox"
      :checked="isChecked"
      :value="value"
      @change="updateInput"
    />
    <label class="form-check-label" :for="id">
      <slot />
    </label>
  </div>
</template>

<script>
import { uniqueId } from 'lodash';

export default {
  model: {
    prop: 'modelValue',
    event: 'change',
  },
  props: {
    'value': { type: [String, Boolean, Number] },
    'modelValue': { default: '' },
    'trueValue': { default: true },
    'falseValue': { default: false },
  },
  data () {
    return {
      id: uniqueId('checkbox-'),
    };
  },
  computed: {
    isChecked () {
      if (Array.isArray(this.modelValue)) {
        return this.modelValue.includes(this.value);
      }
      return this.modelValue === this.trueValue;
    },
  },
  methods: {
    updateInput (event) {
      let isChecked = event.target.checked;
      if (Array.isArray(this.modelValue)) {
        let newValue = [...this.modelValue];
        if (isChecked) {
          newValue.push(this.value);
        } else {
          newValue.splice(newValue.indexOf(this.value), 1);
        }
        this.$emit('change', newValue);
      } else {
        this.$emit('change', isChecked ? this.trueValue : this.falseValue);
      }
    },
  },
};
</script>
