<template>
    <ul class="pagination">
        <li
            :class="['page-item', { disabled: value === 1 }]"
            @click="value !== 1 && $emit('input', value - 1)"
        >
            <button class="page-link">Previous</button>
        </li>
        <li :class="['page-item', { active: page === value }]" v-for="page in leftPages" :key="`left-${page}`">
            <button class="page-link" @click="$emit('input', page)">{{ page }}</button>
        </li>
        <template v-if="exceededMax">
            <li class="page-item disabled">
                <button class="page-link">...</button>
            </li>
            <template v-if="showMiddlePages">
                <li :class="['page-item', { active: page === value }]" v-for="page in middlePages" :key="`middle-${page}`">
                    <button class="page-link" @click="$emit('input', page)">{{ page }}</button>
                </li>
                <li class="page-item disabled">
                    <button class="page-link">...</button>
                </li>
            </template>
            <li :class="['page-item', { active: page === value }]" v-for="page in rightPages" :key="`right-${page}`">
                <button class="page-link" @click="$emit('input', page)">{{ page }}</button>
            </li>
        </template>
        <li :class="['page-item', { disabled: value === pageCount }]">
            <button
                class="page-link"
                :disabled="value === pageCount"
                @click="value !== pageCount && $emit('input', value + 1)"
            >Next</button>
        </li>
    </ul>
</template>

<script>
const MAX_TABS = 10;
const GROUP_MAX = 4;

export default {
    props: {
        value: Number,
        pageCount: Number,
    },
    computed: {
        exceededMax() {
            return this.pageCount >= MAX_TABS;
        },
        leftPages() {
            if (!this.exceededMax) {
                return this.pageCount;
            }
            if (this.value > GROUP_MAX) {
                return 1;
            }
            if (this.value === GROUP_MAX) {
                return GROUP_MAX + 1;
            }
            return GROUP_MAX;
        },
        showMiddlePages() {
            return this.value > GROUP_MAX && this.value < this.pageCount - GROUP_MAX + 1;
        },
        middlePages() {
            return [this.value - 1, this.value, this.value + 1];
        },
        rightPages() {
            if (this.value < this.pageCount - GROUP_MAX + 1) {
                return [this.pageCount];
            }
            if (this.value === this.pageCount - GROUP_MAX + 1) {
                return [...Array(GROUP_MAX + 1).keys()].map(i => this.pageCount - GROUP_MAX + i);
            }
            return [...Array(GROUP_MAX).keys()].map(i => this.pageCount - GROUP_MAX + 1 + i)
        },
    },
};
</script>
