<template>
  <div>
    <base-textarea
      label="Question"
      placeholder="Question text"
      :value="element.prompt"
      @input="updatePrompt"
      required
    />
    <base-button
      size="sm"
      outline
      @click="addAnswer"
      :rules="[rules.answerRequired]"
      :value="element.answers ? element.answers.length : 0"
    >+ Answer</base-button>
    <template-type-answers
      :element="element"
      @input="$emit('input', $event)"
      :free-text-type-options="freeTextTypeOptions"
    />
  </div>
</template>

<script>
import TemplateTypeAnswers from './TemplateTypeAnswers';
import BaseTextarea from '../../base/BaseTextarea';
import BaseButton from '../../base/BaseButton';
import { cloneDeep, uniqueId } from 'lodash';

export default {
  components: {
    TemplateTypeAnswers,
    BaseTextarea,
    BaseButton,
  },
  model: {
    prop: 'element',
  },
  props: {
    element: {
      type: Object,
      required: true,
    },
    freeTextTypeOptions: Array,
  },
  data () {
    return {
      rules: {
        answerRequired: () => (this.element.answers && this.element.answers.length > 0) || 'At least one answer is required',
      },
    };
  },
  methods: {
    updatePrompt (prompt) {
      const clone = cloneDeep(this.element);
      clone.prompt = prompt;
      this.$emit('input', clone);
    },
    addAnswer () {
      const clone = cloneDeep(this.element);
      const newAnswer = {
        id: uniqueId('answer-'),
        text: '',
        freeTextType: this.freeTextTypeOptions ? this.freeTextTypeOptions[0].value : null,
        freeTextLabel: '',
      };
      if (clone.answers) {
        clone.answers.push(newAnswer);
      } else {
        clone.answers = [newAnswer];
      }
      this.$emit('input', clone);
    },
  },
};
</script>
