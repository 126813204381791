<template>
  <split-template-type :title="element.prompt">
    <base-checkbox-group :value="element.response" :required="element.isRequired" v-if="isEditable">
      <div v-for="(answer, index) in element.answers" :key="index">
        <base-checkbox :value="index" @change="updateOption" :model-value="cleanModelValue">
          {{ answer.text }}
        </base-checkbox>
        <base-text-field v-if="showTextField(answer, index)" class="mb-2" :value="getTextValue(index)"
          @input="updateText(index, $event)" />
        <base-textarea v-if="showTextarea(answer, index)" class="mb-2" :value="getTextValue(index)"
          @input="updateText(index, $event)" />
      </div>
    </base-checkbox-group>
    <div v-else>
      <div v-for="(response, index) in element.response" :class="{ 'mb-2': index < element.response.length }"
        :key="index">
        <div class="fw-bolder">{{ element.answers[response.index].text }}</div>
        <div>{{ response.text }}</div>
      </div>
    </div>
  </split-template-type>
</template>

<script>
import SplitTemplateType from './SplitTemplateType';

import BaseCheckboxGroup from '../../base/BaseCheckboxGroup';
import BaseCheckbox from '../../base/BaseCheckbox';
import BaseTextField from '../../base/BaseTextField';
import BaseTextarea from '../../base/BaseTextarea';
import { cloneDeep } from 'lodash';
import freeTextTypes from '../../../constants/freeTextTypes';

export default {
  components: {
    SplitTemplateType,
    BaseCheckboxGroup,
    BaseCheckbox,
    BaseTextField,
    BaseTextarea,
  },
  model: {
    prop: 'element',
  },
  props: {
    element: {
      type: Object,
      required: true,
    },
    isEditable: Boolean
  },
  computed: {
    cleanModelValue () {
      if (this.element.response) {
        return this.element.response.map(r => r.index);
      }

      return [];
    },
  },
  methods: {
    updateOption (value) {
      const clone = cloneDeep(this.element);
      if (!clone.response) {
        clone.response = [];
      }
      value.forEach(v => {
        if (!clone.response.find(r => r.index === v)) {
          clone.response.push({
            index: v,
            text: '',
          });
        }
      });
      clone.response = clone.response.filter(r => value.includes(r.index));
      this.$emit('input', clone);
    },
    updateText (index, value) {
      const clone = cloneDeep(this.element);
      const response = clone.response.find(r => r.index === index);
      response.text = value;
      this.$emit('input', clone);
    },
    getTextValue (index) {
      if (this.element.response) {
        const response = this.element.response.find(r => r.index === index);
        return response ? response.text : null;
      }
      return null;
    },
    showTextField (answer, index) {
      if (this.element.response) {
        const isSelected = !!this.element.response.find(r => r.index === index);
        return isSelected && answer.freeTextType === freeTextTypes.ONE_LINE;
      }
    },
    showTextarea (answer, index) {
      if (this.element.response) {
        const isSelected = !!this.element.response.find(r => r.index === index);
        return isSelected && answer.freeTextType === freeTextTypes.MULTI_LINE;
      }
    },
  },
};
</script>
