<template>
  <form @submit.prevent="$emit('submit')">
    <slot />
  </form>
</template>

<script>
export default {
  props: {
    value: Boolean,
  },
  data () {
    return {
      inputs: [],
      errorBag: {},
    };
  },
  provide () {
    return {
      form: this,
    };
  },
  methods: {
    validate () {
      return this.inputs.filter((input) => !input.validate(true)).length === 0;
    },
    reset () {
      this.inputs.forEach((input) => input.reset());
    },
    register (input) {
      this.inputs.push(input);
      input.$watch(
        'hasError',
        (val) => {
          this.$set(this.errorBag, input._uid, val);
        },
        { immediate: true },
      );
    },
    unregister (input) {
      const found = this.inputs.find((i) => i._uid === input._uid);

      if (!found) return;

      this.inputs = this.inputs.filter((i) => i._uid !== found._uid);
      this.$delete(this.errorBag, found._uid);
    },
  },
  watch: {
    errorBag: {
      handler (val) {
        const errors = Object.values(val).includes(true);
        this.$emit('input', !errors);
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
