<template>
  <div>
    <div v-if="areOptionsLoading" class="d-flex justify-content-center py-5">
      <base-spinner />
    </div>
    <div v-else>
      <div id="service_code_0" class="row px-4">
        <div class="col">
          <label for="Primary Service Code" class="form-label fw-bold">Primary Service Code</label>
          <service-code-selector
              :service-code-options="serviceCodeOptions"
              :value="primaryServiceCode.service_code"
              @input="updateServiceCodes(0, 'service_code', $event)"
              :serviceCodeIndex="0"
          />
        </div>
        <div class="col-2">
          <label for="service_code" class="form-label fw-bold">Time Spent</label>
          <service-code-time-input
              :value="primaryServiceCode.time_spent"
              @input="updateServiceCodes(0, 'time_spent', $event)"
              :serviceCodeIndex="0"
          />
        </div>
        <div class="col-auto p-2">
          <button
              type="button"
              class="btn btn-primary mt-4"
              @click="addServiceCode()"
              :disabled="!canAddServiceCode"
          >Add</button>
        </div>
      </div>
      <div
          :id="`service_code_${serviceCodeIndex + 1}`"
          :class="['row px-4 g-2 mt-1', { 'mb-2 pb-1': serviceCodeIndex }]"
          v-for="(serviceCode, serviceCodeIndex) in additionalServiceCodes"
          :key="serviceCodeIndex"
      >
        <div class="col">
          <label
              for="Additional Service Codes"
              class="form-label fw-bold"
              v-if="!serviceCodeIndex"
          >Additional Service Codes</label>
          <service-code-selector
              :service-code-options="serviceCodeOptions"
              :value="additionalServiceCodes[serviceCodeIndex].service_code"
              @input="updateServiceCodes(serviceCodeIndex + 1, 'service_code', $event)"
              :serviceCodeIndex="serviceCodeIndex + 1"
          />
        </div>
        <div class="col-2">
          <label for="service_code" class="form-label fw-bold" v-if="!serviceCodeIndex">Time Spent</label>
          <service-code-time-input
              :value="additionalServiceCodes[serviceCodeIndex].time_spent"
              @input="updateServiceCodes(serviceCodeIndex + 1, 'time_spent', $event)"
              :serviceCodeIndex="serviceCodeIndex + 1"
          />
        </div>
        <div :class="['col-auto px-1', { 'py-2': !serviceCodeIndex }]">
          <button
              type="button"
              :class="['btn btn-primary', { 'mt-4': !serviceCodeIndex }]"
              @click="addServiceCode"
              :disabled="!canAddServiceCode"
          >Add</button>
        </div>
        <div :class="['col-auto px-1', { 'py-2': !serviceCodeIndex }]">
          <button
              type="button"
              :class="['btn btn-primary', { 'mt-4': !serviceCodeIndex }]"
              @click="removeServiceCode(serviceCodeIndex + 1)"
          >Remove</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ServiceCodeSelector from './ServiceCodeSelector';
import ServiceCodeTimeInput from './ServiceCodeTimeInput';
import BaseSpinner from './base/BaseSpinner';
import serviceCodesServices from './../services/serviceCodesServices.js';

const MAX_SERVICE_CODES = 30;

export default {
  components: {
    ServiceCodeSelector,
    ServiceCodeTimeInput,
    BaseSpinner,
  },
  props: {
    serviceCodes: {
      type: Array,
      default: () => [{ service_code: '', time_spent: null }],
    },
    regionId: {
      type: String,
      required: true,
    }
  },
  data () {
    return {
      areOptionsLoading: false,
      internalServiceCodes: this.serviceCodes.length ? this.serviceCodes : [{ service_code: '', time_spent: null }],
      serviceCodeOptions: [],
    };
  },
  computed: {
    canAddServiceCode () {
      return this.internalServiceCodes.length < MAX_SERVICE_CODES;
    },
    primaryServiceCode () {
      return this.internalServiceCodes.length ? this.internalServiceCodes[0] : {};
    },
    additionalServiceCodes () {
      return this.internalServiceCodes.slice(1, this.internalServiceCodes.length);
    },
  },
  created () {
    this.getServiceCodes();
  },
  methods: {
    async getServiceCodes () {
      try {
        this.areOptionsLoading = true;
        this.serviceCodeOptions = await serviceCodesServices.getServiceCodes({
          regionId: this.regionId
        });
      } finally {
        this.areOptionsLoading = false;
      }
    },
    addServiceCode () {
      if (this.canAddServiceCode) {
        this.internalServiceCodes.push({
          service_code: '',
          time_spent: null,
        });
      }
    },
    removeServiceCode (serviceCodeIndex) {
      this.internalServiceCodes.splice(serviceCodeIndex, 1);
    },
    updateServiceCodes (index, field, value) {
      this.index = index;
      this.internalServiceCodes[index][field] = value;
    },
  },
};
</script>
