import axios from 'axios';

export default {
    createTemplate: async ({ regionId, template }) => {
        await axios.post(`/regions/${regionId}/form-builder-templates`, {data: template} );
    },
    editTemplate: async ({ regionId, template, templateId }) => {
        await axios.put(`/regions/${regionId}/form-builder-templates/${templateId}`, {data: template} );
    },
};
