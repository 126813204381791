<template>
  <split-template-type :title="element.prompt">
    <base-text-field placeholder="Select date" type="date" :value="element.response" @input="updateResponse"
      :required="element.isRequired" v-if="isEditable" />
    <div v-else>
      {{ element.response }}
    </div>
  </split-template-type>
</template>

<script>
import SplitTemplateType from './SplitTemplateType';
import BaseTextField from '../../base/BaseTextField';
import { cloneDeep } from 'lodash';

export default {
  components: {
    SplitTemplateType,
    BaseTextField,
  },
  model: {
    prop: 'element',
  },
  props: {
    element: {
      type: Object,
      required: true,
    },
    isEditable: Boolean
  },
  methods: {
    updateResponse (response) {
      const clone = cloneDeep(this.element);
      clone.response = response;
      this.$emit('input', clone);
    },
  },
};
</script>
