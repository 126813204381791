import axios from 'axios';

export default {
    saveInternalForm: async ({ regionId, formBuilderTemplateId, templateForm, studentId, groupId }) => {
        await axios.post(`/regions/${regionId}/render-form/${formBuilderTemplateId}`, { data: templateForm }, { params: { studentId, groupId } });
    },
    savePublicForm: async ({ regionId, formBuilderTemplateId, templateForm, studentId, groupId }) => {
        await axios.post(`/regions/${regionId}/display-form/${formBuilderTemplateId}`, { data: templateForm }, { params: { studentId, groupId } });
    },
    saveForm: async ({ url, verb, templateForm, studentId, groupId }) => {
        await axios[verb](url, { data: templateForm }, { params: { studentId, groupId } });
    },
};
