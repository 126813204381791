<template>
    <div>
        <div class="container-lg pt-4">
            <div v-if="areSchoolsLoading" class="d-flex justify-content-center py-5">
                <base-spinner />
            </div>
            <div v-else>
                <div class="row gy-2">
                    <div class="col-12 col-md-3">
                        <base-select :options="schoolOptions" v-model="schoolId" />
                    </div>
                    <div class="col-12 col-md-3">
                        <base-select :options="statusOptions" v-model="status" />
                    </div>
                    <div class="col-12 col-md-6">
                        <base-text-field placeholder="Student Search" v-model="search" />
                    </div>
                </div>
                <div v-if="areStudentsLoading" class="d-flex justify-content-center py-5">
                    <base-spinner />
                </div>
                <div v-else>
                    <div class="mt-4 overflow-auto shadow-sm">
                        <base-table :fields="studentFields" :items="studentItems">
                            <template #name="{ item: student }">
                                <a
                                    :href="`/students/${student.id}`"
                                    class="link-primary text-decoration-none"
                                >{{ student.name }}</a>
                            </template>
                            <template #status="{ item: student }">
                                <span class="badge bg-success" v-if="student.active">Active</span>
                                <span class="badge bg-dark" v-else>Inactive</span>
                            </template>
                            <template #empty-state>
                                <div class="py-4 px-3 text-muted">No students found.</div>
                            </template>
                        </base-table>
                    </div>
                    <div class="mt-5">
                        <base-pagination :page-count="pageCount" v-model="currentPage" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BaseSpinner from './base/BasePagination';
import BaseSelect from './base/BaseSelect';
import BaseTextField from './base/BaseTextField';
import BaseTable from './base/BaseTable';
import BasePagination from './base/BasePagination';
import schoolServices from './../services/schoolServices.js';
import studentServices from './../services/studentServices';



const debounce = function (func, timeout = 300) {
    let timer;
    return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => { func.apply(this, args); }, timeout);
    };
};

export default {
    components: {
        BaseSpinner,
        BaseSelect,
        BaseTextField,
        BaseTable,
        BasePagination,
    },
    props: {
        regionId: {
            type: String,
            required: true
        }
    },
    data () {
        return {
            areSchoolsLoading: false,
            areStudentsLoading: false,
            schoolId: '',
            status: '',
            search: '',
            currentPage: 1,
            pageCount: null,
            schools: [],
            students: [],
            statusOptions: [
                { value: '', label: 'All Students' },
                { value: 'true', label: 'Active' },
                { value: 'false', label: 'Inactive' },
            ],
            studentFields: [
                { key: 'name', label: 'Student Name' },
                { key: 'studentId', label: 'Student Id' },
                { key: 'attending', label: 'Attending' },
                { key: 'gradeLevel', label: 'Grade' },
                { key: 'status', label: 'Active' },
            ],
        };
    },
    computed: {
        schoolOptions () {
            return [
                { value: '', label: 'All Schools' },
                ...this.schools.map(school => ({ value: school.id, label: school.name })),
            ];
        },
        studentItems () {
            return this.students.map(student => ({
                id: student.id,
                name: student.student_name,
                studentId: student.student_id,
                attending: (this.schools.find(school => school.id === student.school_id) || {}).name,
                gradeLevel: student.grade_level,
                active: student.active,
            }));
        },
    },
    created () {
        this.debounceGetStudents = debounce(this.getStudents);
        this.getSchools();
        this.getStudents();
    },
    methods: {
        async getSchools () {
            try {
                this.areSchoolsLoading = true;
                this.schools = await schoolServices.getSchools({
                    regionId: this.regionId
                });
            } finally {
                this.areSchoolsLoading = false;
            }
        },
        async getStudents () {
            try {
                this.areStudentsLoading = true;
                const response = await studentServices.getStudents({
                    schoolId: this.schoolId,
                    status: this.status,
                    search: this.search,
                    page: this.currentPage,
                });
                this.pageCount = response.numberOfPages;
                this.currentPage = Math.min(this.pageCount, Math.max(response.currentPage, 1));
                this.students = response.students;
            } finally {
                this.areStudentsLoading = false;
            }
        },
    },
    watch: {
        status () {
            this.getStudents();
        },
        schoolId () {
            this.getStudents();
        },
        currentPage () {
            this.getStudents();
        },
        search () {
            this.debounceGetStudents();
        },
    },
};
</script>
