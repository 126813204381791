<template>
  <div class="form-check">
    <input
      :id="id"
      class="form-check-input"
      type="radio"
      :value="value"
      :name="name"
      @change="updateInput"
      :checked="isChecked"
    />
    <label class="form-check-label" :for="id">
      <slot />
    </label>
  </div>
</template>

<script>
import { uniqueId } from 'lodash';

export default {
  model: {
    prop: 'modelValue',
    event: 'change',
  },
  props: {
    value: { type: [String, Number] },
    name: { type: String },
    modelValue: { default: '' },
  },
  data () {
    return {
      id: uniqueId('radio-'),
    };
  },
  computed: {
    isChecked () {
      return this.modelValue === this.value;
    },
  },
  methods: {
    updateInput () {
      this.$emit('change', this.value);
    },
  },
};
</script>
