<template>
  <base-form ref="form" v-model="isFormValid">
    <h3>{{ templateData.displayName }}</h3>
    <div class="border-top mt-3 pt-3" v-for="(element, index) in editTemplateData.elements" :key="index">
      <component :is="getComponentType(element.type)" v-model="editTemplateData.elements[index]" :is-editable="isEditable" />
    </div>
    <div class="border-top pt-3 mt-3" v-if="!isPreview">
      <base-button :disabled="!isFormValid" @click="submit">Submit Form</base-button>
    </div>
  </base-form>
</template>

<script>
import BaseForm from '../base/BaseForm';
import BaseButton from '../base/BaseButton';
import TemplateTypeLongAnswer from './templateTypes/TemplateTypeLongAnswer';
import TemplateTypeShortAnswer from './templateTypes/TemplateTypeShortAnswer';
import TemplateTypeShortAnswerGroups from './templateTypes/TemplateTypeShortAnswerGroups';
import TemplateTypeMultipleChoice from './templateTypes/TemplateTypeMultipleChoice';
import TemplateTypeSingleChoice from './templateTypes/TemplateTypeSingleChoice';
import TemplateTypeDropdown from './templateTypes/TemplateTypeDropdown';
import TemplateTypeDate from './templateTypes/TemplateTypeDate';
import TemplateTypeParagraph from './templateTypes/TemplateTypeParagraph';
import TemplateTypeSectionHeader from './templateTypes/TemplateTypeSectionHeader';
import TemplateTypeSectionBreak from './templateTypes/TemplateTypeSectionBreak';
import elementTypes from '../../constants/elementTypes';
import { cloneDeep } from 'lodash';
import templateTypes from '../../constants/templateTypes';
import templateFormServices from '../../services/templateFormServices';

export default {
  components: {
    BaseForm,
    BaseButton,
    TemplateTypeLongAnswer,
    TemplateTypeShortAnswer,
    TemplateTypeShortAnswerGroups,
    TemplateTypeMultipleChoice,
    TemplateTypeSingleChoice,
    TemplateTypeDropdown,
    TemplateTypeDate,
    TemplateTypeParagraph,
    TemplateTypeSectionHeader,
    TemplateTypeSectionBreak,
  },
  props: {
    formBuilderTemplateId: {
      type: String,
      required: true,
    },
    templateData: {
      type: Object,
      required: true,
    },
    regionId: String,
    studentId: String,
    groupId: String,
    isPreview: Boolean,
    isEditable: {
      type: Boolean,
      default: true
    },
    postUrl: {
      type: String,
      default() {
        return `/regions/${this.regionId}/render-form/${this.formBuilderTemplateId}`
      }
    },
    verb: {
      type: String,
      default: 'post'
    },
    returnUrl: String
  },
  data () {
    return {
      isFormValid: true,
      editTemplateData: cloneDeep(this.templateData),
    };
  },
  methods: {
    getComponentType (type) {
      switch (type) {
        case elementTypes.LONG_ANSWER:
          return 'template-type-long-answer';
        case elementTypes.SHORT_ANSWER:
          return 'template-type-short-answer';
        case elementTypes.SHORT_ANSWER_GROUPS:
          return 'template-type-short-answer-groups';
        case elementTypes.MULTIPLE_CHOICE:
          return 'template-type-multiple-choice';
        case elementTypes.SINGLE_CHOICE:
          return 'template-type-single-choice';
        case elementTypes.DROPDOWN:
          return 'template-type-dropdown';
        case elementTypes.DATE:
          return 'template-type-date';
        case elementTypes.PARAGRAPH:
          return 'template-type-paragraph';
        case elementTypes.SECTION_HEADER:
          return 'template-type-section-header';
        case elementTypes.SECTION_BREAK:
          return 'template-type-section-break';
      }
    },
    async submit () {
      if (!this.$refs.form.validate()) return;

      try {
        this.isLoading = true;

        await templateFormServices.saveForm({
          url: this.postUrl,
          verb: this.verb,
          studentId: this.studentId,
          groupID: this.groupId,
          templateForm: this.editTemplateData,
        })
        if(this.returnUrl) {
          window.location.href = this.returnUrl;
        }
      }
      finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
