<template>
  <div class="position-relative" v-clickoutside="handleClickOutside">
    <input
        :value="value"
        :name='`service_codes[${this.serviceCodeIndex}][service_code]`'
        aria-label="Service Code"
        required="required"
        class="form-select"
        @keypress="$event.preventDefault()"
        @click="openOptions"
        placeholder="Select service code"
        autocomplete="off"
    />
    <div
        class="rounded position-absolute bg-white shadow px-2 w-100 options-wrapper"
        v-show="isOpen"
    >
      <div class="pt-3 pb-2 px-2 position-sticky top-0 bg-white">
        <input
            v-model="search"
            placeholder="Type here to search service codes"
            class="form-control"
            ref="search"
        />
      </div>
      <div
          v-for="serviceCode in filteredServiceCodeOptions"
          :key="serviceCode"
          class="p-2 rounded service-code-option"
          @click="handleInput(serviceCode)"
      >{{ serviceCode }}</div>
    </div>
  </div>
</template>

<script>
export default {
  directives: {
    clickoutside: {
      inserted: (el, binding, vnode) => {
        el.clickOutsideEvent = function (event) {
          if (!(el === event.target || el.contains(event.target))) {
            vnode.context[binding.expression](event);
          }
        };
        document.body.addEventListener('click', el.clickOutsideEvent);
        document.body.addEventListener('touchstart', el.clickOutsideEvent);
      },
      unbind: function (el) {
        document.body.removeEventListener('click', el.clickOutsideEvent);
        document.body.removeEventListener('touchstart', el.clickOutsideEvent);
      },
      stopProp (event) {
        event.stopPropagation();
      },
    },
  },
  props: {
    value: String,
    serviceCodeOptions: Array,
    serviceCodeIndex: Number,
  },
  data () {
    return {
      isOpen: false,
      search: '',
    };
  },
  computed: {
    filteredServiceCodeOptions () {
      return this.serviceCodeOptions ? this.serviceCodeOptions.filter(sco => sco.toLowerCase().includes(this.search.toLowerCase())) : [];
    },
  },
  methods: {
    handleClickOutside () {
      this.isOpen = false;
      this.search = '';
    },
    handleInput (serviceCode) {
      this.$emit('input', serviceCode);
      this.search = '';
      this.isOpen = false;
    },
    openOptions () {
      this.isOpen = true;
      this.$nextTick(() => (this.$refs.search.focus()));
    },
  },
};
</script>

<style lang="scss" scoped>
.service-code-option {
  cursor: pointer;
  &:hover {
    background-color: #efefef;
  }
}
.options-wrapper {
  max-height: 30rem;
  overflow: auto;
  z-index: 1000;
}
</style>
