<template>
  <div v-if="element.answers">
    <div v-for="answer in element.answers" :key="answer.id" class="row">
      <div class="col-sm-4 d-flex align-items-start">
        <base-text-field
          placeholder="Answer text"
          @input="updateAnswer(answer.id, 'text', $event)"
          :value="answer.text"
          required
        />
        <base-button
          type="default"
          @click="deleteAnswer(answer.id)"
          v-if="!freeTextTypeOptions.length"
        >
          <trash-icon class="icon text-muted" />
        </base-button>
      </div>
      <div class="col-sm-4 d-flex align-items-start" v-if="freeTextTypeOptions.length">
        <base-select
          placeholder="Select type"
          :options="freeTextTypeOptions"
          @input="updateAnswer(answer.id, 'freeTextType', +$event)"
          :value="answer.freeTextType"
        />
        <base-button
          type="default"
          @click="deleteAnswer(answer.id)"
          v-if="!showFreeTextLabel(answer)"
        >
          <trash-icon class="icon text-muted" />
        </base-button>
      </div>
      <div class="col-sm-4 d-flex align-items-start" v-if="showFreeTextLabel(answer)">
        <base-text-field
          placeholder="Free text label"
          @input="updateAnswer(answer.id, 'freeTextLabel', $event)"
          :value="answer.freeTextLabel"
          required
        />
        <base-button type="default" @click="deleteAnswer(answer.id)">
          <trash-icon class="icon text-muted" />
        </base-button>
      </div>
    </div>
  </div>
</template>

<script>
import BaseTextField from '../../base/BaseTextField';
import BaseSelect from '../../base/BaseSelect';
import BaseButton  from '../../base/BaseButton';

import TrashIcon from '../../../icons/TrashIcon';
import { cloneDeep } from 'lodash';
import freeTextTypes from '../../../constants/freeTextTypes';

export default {
  components: {
    BaseTextField,
    BaseSelect,
    BaseButton,
    TrashIcon,
  },
  model: {
    prop: 'element',
  },
  props: {
    element: {
      type: Object,
      required: true,
    },
    freeTextTypeOptions: {
      type: Array,
      default: () => [],
    },
  },
  data () {
    return {
      freeTextTypes,
    };
  },
  created () {
    this.checkTypes();
  },
  methods: {
    updateAnswer (answerId, key, value) {
      const clone = cloneDeep(this.element);
      const answer = clone.answers.find(a => a.id === answerId);
      answer[key] = value;
      this.$emit('input', clone);
    },
    deleteAnswer (answerId) {
      const clone = cloneDeep(this.element);
      clone.answers = clone.answers.filter(a => a.id !== answerId);
      this.$emit('input', clone);
    },
    checkTypes () {
      const clone = cloneDeep(this.element);
      if (clone.answers) {
        clone.answers.forEach(a => {
          if (!this.freeTextTypeOptions.find(ftto => ftto.value === a.freeTextType)) {
            a.freeTextType = this.freeTextTypeOptions.length ? this.freeTextTypeOptions[0].value : null;
          }
        });
        this.$emit('input', clone);
      }
    },
    showFreeTextLabel (answer) {
      return answer.freeTextType &&
        answer.freeTextType !== freeTextTypes.NONE &&
        answer.freeTextType !== freeTextTypes.SHORT_TEXT;
    },
  },
};
</script>
<style lang="scss" scoped>
.icon {
  height: 24px;
  width: 24px;
}
</style>
