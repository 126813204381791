require('./bootstrap');
import 'bootstrap';
import Vue from 'vue';


//Vue.component('example-component', require('./components/ExampleComponent.vue').default);
Vue.component('student-component', require('./components/StudentComponent.vue').default);
Vue.component('service-code-component', require('./components/ServiceCodeComponent.vue').default);
Vue.component('student-groups', require('./components/StudentGroups.vue').default);
Vue.component('template-builder', require('./components/templateBuilder/TemplateBuilder.vue').default);
Vue.component('template-form', require('./components/templateForm/templateForm.vue').default);

const app = new Vue({
    el: '#app',
    data: function(){
        return {
        }
    }
});
