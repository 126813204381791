export default {
  inject: {
    form: {
      default: null,
    },
  },
  props: {
    rules: {
      type: Array,
      default: () => [],
    },
    required: Boolean,
  },
  data () {
    return {
      valid: false,
      errors: [],
      isResetting: false,
    };
  },
  computed: {
    hasError () {
      return !!this.errors.length;
    },
    internalRules () {
      return [...(this.required ? [(value) => (Array.isArray(value) ? !!value.length : (!!value || value === 0)) || 'Required'] : []), ...this.rules];
    },
  },
  created () {
    this.form && this.form.register(this);
  },
  beforeDestroy () {
    this.form && this.form.unregister(this);
  },
  methods: {
    validate () {
      if (!this.internalRules) return;

      const errors = [];

      this.internalRules.some((rule) => {
        const message = rule(this.value);
        if (message !== true) {
          errors.push(message);
        }
      });

      this.errors = errors;
      this.valid = !errors.length;

      return this.valid;
    },
  },
  watch: {
    value () {
      if (this.hasError && !this.isResetting) {
        this.validate();
      }
    },
    isResetting () {
      this.errors = [];
      this.$nextTick(() => (this.isResetting = false));
    },
  },
};
