<template>
  <base-input :rules="internalRules" :errors="errors">
    <label :for="id" class="form-label" v-if="label">{{ label }}</label>
    <select
      :class="['form-select', { 'is-invalid': errors.length }]"
      :aria-label="label"
      @change="$emit('input', $event.target.value)"
      :id="id"
      @blur="validate"
    >
      <option selected disabled v-if="placeholder">{{ placeholder }}</option>
      <option
        v-for="option in options"
        :key="option.value"
        :value="option.value"
        :selected="option.value === value"
      >{{ option[labelKey] }}</option>
    </select>
  </base-input>
</template>

<script>
import BaseInput from './BaseInput';
import validatable from '../mixins/validatable';
import { uniqueId } from 'lodash';

export default {
  mixins: [validatable],
  components: {
    BaseInput,
  },
  props: {
    label: String,
    value: [String, Number, Boolean],
    options: {
      type: Array,
      required: true,
    },
    labelKey: {
      type: String,
      default: 'label',
    },
    placeholder: String,
  },
  data () {
    return {
      id: uniqueId('select-'),
    };
  },
};
</script>
