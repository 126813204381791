<template>
  <split-template-type :title="element.prompt">
    <base-select placeholder="Select an option" :options="options" @input="updateResponse" :value="element.response"
      :required="element.isRequired" v-if="isEditable" />
    <div v-else>
      {{ element.answers[element.response] && element.answers[element.response].text }}
    </div>
  </split-template-type>
</template>

<script>
import SplitTemplateType from './SplitTemplateType';
import BaseSelect from '../../base/BaseSelect';
import { cloneDeep } from 'lodash';

export default {
  components: {
    SplitTemplateType,
    BaseSelect,
  },
  model: {
    prop: 'element',
  },
  props: {
    element: {
      type: Object,
      required: true,
    },
    isEditable: Boolean
  },
  computed: {
    options () {
      return this.element.answers.map((a, index) => ({
        label: a.text,
        value: index,
      }));
    },
  },
  methods: {
    updateResponse (response) {
      const clone = cloneDeep(this.element);
      clone.response = +response;
      this.$emit('input', clone);
    },
  },
};
</script>
