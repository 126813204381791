<template>
  <split-template-type :title="element.prompt">
    <div v-if="isEditable">
      <base-text-field :label="answer.text" placeholder="Enter response"
        :value="element.response ? element.response[index] : null" @input="updateResponse(index, $event)"
        :required="element.isRequired" v-for="(answer, index) in element.answers" :datalist="getDatalist(answer)"
        :key="index" />
    </div>
    <div v-else>
      <div v-for="(response, index) in element.response" :key="index">
        <div class="fw-bolder">{{ element.answers[index].text }}</div>
        <div>{{ response }}</div>
      </div>
    </div>
  </split-template-type>
</template>

<script>
import SplitTemplateType from './SplitTemplateType';
import BaseTextField from '../../base/BaseTextField';
import { cloneDeep } from 'lodash';
import freeTextTypes from '../../../constants/freeTextTypes';

export default {
  components: {
    SplitTemplateType,
    BaseTextField,
  },
  model: {
    prop: 'element',
  },
  props: {
    element: {
      type: Object,
      required: true,
    },
    isEditable: Boolean
  },
  methods: {
    updateResponse (index, response) {
      const clone = cloneDeep(this.element);
      if (!clone.response) {
        clone.response = [];
      }
      clone.response[index] = response;
      this.$emit('input', clone);
    },
    getDatalist (answer) {
      if (answer.freeTextType === freeTextTypes.DROPDOWN) {
        return answer.freeTextLabel.split(',').map(x => x.trim());
      }

      return [];
    },
  },
};
</script>
