<template>
  <div>
    <slot />
    <div class="fs-7 text-danger rules" v-if="rules.length">
      <div v-show="errors.length">{{ message }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    rules: {
      type: Array,
      default: () => [],
    },
    errors: {
      type: Array,
      default: () => [],
    },
  },
  data () {
    return {
      leavingMessage: '',
    };
  },
  computed: {
    message () {
      return this.leavingMessage || (this.errors.length ? this.errors[0] : '');
    },
  },
  watch: {
    errors (newVal, oldVal) {
      if (!newVal.length && oldVal.length) {
        this.leavingMessage = oldVal[0];
        setTimeout(() => {
          this.leavingMessage = '';
        }, 200);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.rules {
  height: 18px;
}
</style>
