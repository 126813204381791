<template>
  <base-input :rules="internalRules" :errors="errors">
    <button
      :class="['btn d-inline-flex align-items-center justify-content-center', `btn-${outline ? 'outline-' : ''}${type}`, `btn-${size}`, buttonClass]"
      @click="$emit('click')"
      :disabled="disabled"
      type="button"
    >
      <base-spinner v-if="loading" color="white" class="position-absolute spinner-border-sm" />
      <div :class="[{invisible: loading}]">
        <slot />
      </div>
    </button>
  </base-input>
</template>

<script>

import BaseInput  from './BaseInput';
import BaseSpinner  from './BaseSpinner';
import validatable from '../mixins/validatable';

export default {
  mixins: [validatable],
  components: {
    BaseInput,
    BaseSpinner,
  },
  props: {
    loading: Boolean,
    type: {
      type: String,
      default: 'primary',
    },
    size: String,
    disabled: Boolean,
    outline: Boolean,
    value: [String, Number],
    buttonClass: String,
  },
};
</script>
