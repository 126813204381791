import axios from 'axios';

export default {
    getStudents: async ({ schoolId, status, search, page }) => {
        const response = await axios.post('/search/students', {
            search: {
                student_search: search,
                school_id: schoolId,
                active: status,
                page
            }
        })
        return {
            students: response.data.students,
            currentPage: response.data.current_page,
            numberOfPages: response.data.number_of_pages,
        }
    }
};
