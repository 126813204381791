export default {
  LONG_ANSWER: 1,
  SHORT_ANSWER: 2,
  SINGLE_CHOICE: 3,
  MULTIPLE_CHOICE: 4,
  SHORT_ANSWER_GROUPS: 5,
  DROPDOWN: 6,
  DATE: 7,
  PARAGRAPH: 8,
  SECTION_HEADER: 9,
  SECTION_BREAK: 10,
};
