<template>
  <input
      class="form-control"
      :name="`service_codes[${this.serviceCodeIndex}][time_spent]`"
      :value="value"
      @input="$emit('input', $event.target.value)"
      required="required"
      @keypress="isNumber($event)"
      placeholder="Enter time spent"
  />
</template>

<script>
export default {
  props: {
    value: [String, Number],
    serviceCodeIndex: Number
  },
  methods: {
    isNumber (event) {
      event = (event) || window.event;
      let charCode = (event.which) ? event.which : event.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        event.preventDefault();
      } else {
        return true;
      }
    },
  },
};
</script>
