<template>
  <table class="app-table rounded shadow-sm">
    <thead>
      <tr>
        <th v-for="field in fields" :key="field.key">{{ field.label }}</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(item, index) in items" :key="index">
        <td v-for="field in fields" :key="`${index}-${field.key}`">
          <slot :item="item" :index="index" :name="field.key">{{ item[field.key] }}</slot>
        </td>
      </tr>
    </tbody>
    <div v-if="!items.length && $slots['empty-state']">
      <slot name="empty-state" />
    </div>
  </table>
</template>

<script>
export default {
  props: {
    fields: {
      type: Array,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
  },
};
</script>
