<template>
  <div>
    <base-textarea
      label="Question"
      placeholder="Question text"
      :value="element.prompt"
      @input="updatePrompt"
      required
    />
  </div>
</template>

<script>
import BaseTextarea from '../../base/BaseTextarea';
import { cloneDeep } from 'lodash';

export default {
  components: {
    BaseTextarea,
  },
  model: {
    prop: 'element',
  },
  props: {
    element: {
      type: Object,
      required: true,
    },
  },
  methods: {
    updatePrompt (prompt) {
      const clone = cloneDeep(this.element);
      clone.prompt = prompt;
      this.$emit('input', clone);
    },
  },
};
</script>
