<template>
    <div>
        <base-form class="d-flex justify-content-between" ref="form" v-model="isFormValid">
            <div class="flex-grow-1" style="max-width: 800px">
                <div class="d-flex">
                    <base-text-field
                        class="w-60 flex-shrink-0"
                        placeholder="New Template"
                        required
                        v-model="template.templateName"
                    />
                    <base-select
                        :options="templateTypeOptions"
                        v-model="template.templateType"
                        class="mx-2 w-40"
                    />
                </div>
                <div class="d-flex">
                    <base-text-field
                        class="w-60 flex-shrink-0"
                        placeholder="Display name"
                        required
                        v-model="template.displayName"
                    />
                    <base-select :options="languageOptions" v-model="template.language" class="mx-2 w-40" />
                </div>
            </div>
            <div class="flex-shrink-0 d-flex align-items-start justify-content-end">
                <base-button type="light" class="mx-2" @click="goToFormBuilderHome">Cancel</base-button>
                <base-button @click="saveTemplate" :disabled="!isFormValid" :loading="isSaving" :rules="[rules.atLeastOneQuestion]">Save</base-button>
            </div>
        </base-form>
        <div class="mt-4">
            <div class="d-flex fw-bolder text-muted fs-7 py-2 border-bottom">
                <div class="order-column">Order</div>
                <div class="name-column">Question Name / Form Element</div>
                <div class="type-column">Type</div>
                <div class="required-column">Required</div>
                <div class="action-column" />
            </div>
            <vue-draggable v-model="template.elements" animation="150" handle=".move-icon">
                <template-element
                    v-for="(element, index) in template.elements"
                    :key="element.id"
                    v-model="template.elements[index]"
                    @delete="deleteElement(index)"
                    :ref="`element-${element.id}`"
                />
            </vue-draggable>
        </div>
        <base-button class="mt-4" @click="addNewElement">Add New</base-button>
    </div>
</template>

<script>
import VueDraggable from 'vuedraggable';
import TemplateElement from './TemplateElement.vue';
import BaseTextField from '../base/BaseTextField';
import BaseButton from '../base/BaseButton';
import BaseSelect from '../base/BaseSelect';
import BaseForm from '../base/BaseForm';


import { uniqueId } from 'lodash';
import templateTypes from '../../constants/templateTypes.js';
import languageTypes from '../../constants/languageTypes.js';


import { getCleanTemplate } from '../../utils/templateBuilder';

import templateBuilderServices from '../../services/templateBuilderServices';

export default {
    components: {
        VueDraggable,
        TemplateElement,
        BaseTextField,
        BaseButton,
        BaseSelect,
        BaseForm,
    },
    props: {
        templateId: String,
        templateJson: String,
        regionId: {
            type: String,
            required: true
        }
    },
    data () {
        return {
            isSaving: false,
            isFormValid: true,
            rules: {
                atLeastOneQuestion: () => !!this.hasTemplateElements || 'Question required'
            },
            template: {
                templateName: '',
                displayName: '',
                templateType: 1,
                elements: [],
                language: languageTypes.ENGLISH,
            },
            templateTypeOptions: [
                { value: templateTypes.PROGRESS_NOTES, label: 'Progress Notes' },
                { value: templateTypes.ASSESSMENTS, label: 'Assessments' },
                //{ value: templateTypes.CONSENT, label: 'Consent' },
                //{ value: templateTypes.REFERRALS, label: 'Referrals' },
                //{ value: templateTypes.RELEASE_OF_INFORMATION, label: 'Release of Information' },
                //{ value: templateTypes.GROUP_NOTES, label: 'Group Notes' },
            ],
            languageOptions: [
                { value: languageTypes.ENGLISH, label: 'English' },
                { value: languageTypes.SPANISH, label: 'Spanish' },
            ],
        };
    },
    computed: {
        hasTemplateElements() {
            return !!this.template.elements.length;
        }
    },
    created() {
        if(this.templateJson) {
            this.template = JSON.parse(this.templateJson)
        }
    },
    methods: {
        async saveTemplate () {
            this.template.elements.forEach(e => {
                const element = this.$refs[`element-${e.id}`][0];
                if(element.isEditing) {
                    element.save();
                }
            })
            if (!this.$refs.form.validate() || this.template.elements.find(e => e.isEditing)) return;
            try {
                this.isSaving = true;
                if(this.templateId) {
                    await templateBuilderServices.editTemplate({
                        template: getCleanTemplate(this.template),
                        templateId: this.templateId,
                        regionId: this.regionId
                    });
                } else {
                    await templateBuilderServices.createTemplate({
                        template: getCleanTemplate(this.template),
                        regionId: this.regionId
                    });
                }
                this.goToFormBuilderHome();
            } finally {
                this.isSaving = false;
            }
        },
        goToFormBuilderHome() {
            window.location.href = `/regions/${this.regionId}/form-builder-templates`;
        },
        addNewElement () {
            this.template.elements.push({
                id: uniqueId('element-'),
                isEditing: true,
                type: 1,
                isRequired: false,
                isNew: true,
            });
        },
        deleteElement (index) {
            this.template.elements.splice(index, 1);
        },
    },
    watch: {
        hasTemplateElements(newValue, oldValue) {
            if(newValue && !oldValue && !this.isFormValid) {
                this.$refs.form.validate();
            }
        }
    }
};
</script>

<style lang="scss">
.order-column {
    width: 6%;
    text-align: center;
}
.name-column {
    width: 67%;
    padding: 0 0.75rem;
}
.type-column {
    width: 12%;
    white-space: nowrap;
}
.required-column {
    width: 10%;
    text-align: center;
}
.action-column {
    width: 5%;
}
.fs-7 {
    font-size: 0.75rem !important;
}
</style>
