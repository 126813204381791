<template>
  <div class="row">
    <div class="col-12 col-md-4 pt-1">
      {{ title }}
    </div>
    <div class="col-12 col-md-6">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
  },
};
</script>
