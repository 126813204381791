<template>
  <split-template-type :title="element.prompt">
    <base-radio-group :value="element.response && element.response.option" :required="element.isRequired"
      v-if="isEditable">
      <div v-for="(answer, index) in element.answers" :key="index">
        <base-radio :name="name" :value="index" @change="updateRadioField($event)"
          :model-value="element.response ? element.response.option : null">
          {{ answer.text }}
        </base-radio>
        <base-text-field v-if="showTextField(answer, index)" class="mb-2"
          :value="element.response && element.response.text" @input="updateResponseField('text', $event)" />
        <base-textarea v-if="showTextarea(answer, index)" class="mb-2"
          :value="element.response && element.response.text" @input="updateResponseField('text', $event)" />
      </div>
    </base-radio-group>
    <div v-else-if="selectedAnswer">
      <div class="fw-bolder">{{ selectedAnswer.text }}</div>
      <div>{{ element.response.text }}</div>
    </div>
  </split-template-type>
</template>

<script>
import SplitTemplateType from './SplitTemplateType';

import BaseRadioGroup from '../../base/BaseRadioGroup';
import BaseRadio from '../../base/BaseRadio';
import BaseTextarea from '../../base/BaseTextarea';
import BaseTextField from '../../base/BaseTextField';
import { cloneDeep, uniqueId } from 'lodash';
import freeTextTypes from '../../../constants/freeTextTypes';

export default {
  components: {
    SplitTemplateType,
    BaseRadioGroup,
    BaseRadio,
    BaseTextField,
    BaseTextarea,
  },
  model: {
    prop: 'element',
  },
  props: {
    element: {
      type: Object,
      required: true,
    },
    isEditable: Boolean
  },
  data () {
    return {
      name: uniqueId('radio-'),
    };
  },
  computed: {
    selectedAnswer () {
      return this.element.response && this.element.response.option ? this.element.answers[this.element.response.option] : null
    }
  },
  methods: {
    updateRadioField (value) {
      this.updateResponseField('option', value);
      this.$nextTick(() => {
        this.updateResponseField('text', null);
      });
    },
    updateResponseField (field, value) {
      const clone = cloneDeep(this.element);
      if (!clone.response) {
        clone.response = {};
      }
      clone.response[field] = value;
      this.$emit('input', clone);
    },
    showTextField (answer, index) {
      return this.element.response && this.element.response.option === index && answer.freeTextType === freeTextTypes.ONE_LINE;
    },
    showTextarea (answer, index) {
      return this.element.response && this.element.response.option === index && answer.freeTextType === freeTextTypes.MULTI_LINE;
    },
  },
};
</script>
