<template>
  <div class="row">
    <div class="col-10 pt-1">
      {{ element.prompt }}
    </div>
  </div>
</template>

<script>
export default {
  model: {
    prop: 'element',
  },
  props: {
    element: {
      type: Object,
      required: true,
    },
  },
};
</script>
