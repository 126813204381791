import elementTypes from '../constants/elementTypes';
import freeTextTypes from '../constants/freeTextTypes';

const getCleanLongAnswer = (element) => ({
  type: element.type,
  prompt: element.prompt,
  isRequired: element.isRequired,
});

const getCleanShortAnswer = (element) => ({
  type: element.type,
  prompt: element.prompt,
  isRequired: element.isRequired,
});

const getCleanSingleChoice = (element) => ({
  type: element.type,
  prompt: element.prompt,
  isRequired: element.isRequired,
  answers: element.answers.map(a => ({
    text: a.text,
    freeTextType: a.freeTextType,
    freeTextLabel: a.freeTextType !== freeTextTypes.NONE ? a.freeTextLabel : null,
  })),
});

const getCleanMultipleChoice = (element) => ({
  type: element.type,
  prompt: element.prompt,
  isRequired: element.isRequired,
  answers: element.answers.map(a => ({
    text: a.text,
    freeTextType: a.freeTextType,
    freeTextLabel: a.freeTextType !== freeTextTypes.NONE ? a.freeTextLabel : null,
  })),
});

const getCleanShortAnswerGroups = (element) => ({
  type: element.type,
  prompt: element.prompt,
  isRequired: element.isRequired,
  answers: element.answers.map(a => ({
    text: a.text,
    freeTextType: a.freeTextType,
    freeTextLabel: a.freeTextType !== freeTextTypes.SHORT_TEXT ? a.freeTextLabel : null,
  })),
});

const getCleanDropdown = (element) => ({
  type: element.type,
  prompt: element.prompt,
  isRequired: element.isRequired,
  answers: element.answers.map(a => ({
    text: a.text,
    freeTextType: null,
    freeTextLabel: null,
  })),
});

const getCleanDate = (element) => ({
  type: element.type,
  prompt: element.prompt,
  isRequired: element.isRequired,
});

const getCleanParagraph = (element) => ({
  type: element.type,
  prompt: element.prompt,
});

const getCleanSectionHeader = (element) => ({
  type: element.type,
  prompt: element.prompt,
});

const getCleanSectionBreak = (element) => ({
  type: element.type,
  prompt: element.prompt,
});

const getCleanElement = (element) => {
  switch (element.type) {
    case elementTypes.LONG_ANSWER:
      return getCleanLongAnswer(element);
    case elementTypes.SHORT_ANSWER:
      return getCleanShortAnswer(element);
    case elementTypes.SINGLE_CHOICE:
      return getCleanSingleChoice(element);
    case elementTypes.MULTIPLE_CHOICE:
      return getCleanMultipleChoice(element);
    case elementTypes.SHORT_ANSWER_GROUPS:
      return getCleanShortAnswerGroups(element);
    case elementTypes.DROPDOWN:
      return getCleanDropdown(element);
    case elementTypes.DATE:
      return getCleanDate(element);
    case elementTypes.PARAGRAPH:
      return getCleanParagraph(element);
    case elementTypes.SECTION_HEADER:
      return getCleanSectionHeader(element);
    case elementTypes.SECTION_BREAK:
      return getCleanSectionBreak(element);
  }
};

export const getCleanTemplate = (template) => ({
  templateName: template.templateName,
  displayName: template.displayName,
  templateType: template.templateType,
  language: template.language,
  elements: template.elements.map(getCleanElement),
});
